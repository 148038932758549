@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 300;
  src: url('greycliff/GreycliffCF-Light.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Light.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 300;
  src: url('greycliff/GreycliffCF-LightOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-LightOblique.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 400;
  src: url('greycliff/GreycliffCF-Regular.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 400;
  src: url('greycliff/GreycliffCF-Regular.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 500;
  src: url('greycliff/GreycliffCF-Medium.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 500;
  src: url('greycliff/GreycliffCF-MediumOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-MediumOblique.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 600;
  src: url('greycliff/GreycliffCF-DemiBold.woff2') format('woff2'),
    url('greycliff/GreycliffCF-DemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 600;
  src: url('greycliff/GreycliffCF-DemiBoldOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-DemiBoldOblique.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 700;
  src: url('greycliff/GreycliffCF-Bold.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 700;
  src: url('greycliff/GreycliffCF-BoldOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-BoldOblique.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 800;
  src: url('greycliff/GreycliffCF-ExtraBold.woff2') format('woff2'),
    url('greycliff/GreycliffCF-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 800;
  src: url('greycliff/GreycliffCF-ExtraBoldOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-ExtraBoldOblique.woff') format('woff');
}

@font-face {
  font-family: 'Greycliff CF';
  font-style: normal;
  font-weight: 900;
  src: url('greycliff/GreycliffCF-Heavy.woff2') format('woff2'),
    url('greycliff/GreycliffCF-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Greycliff CF';
  font-style: italic;
  font-weight: 900;
  src: url('greycliff/GreycliffCF-HeavyOblique.woff2') format('woff2'),
    url('greycliff/GreycliffCF-HeavyOblique.woff') format('woff');
}
