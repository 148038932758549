@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('inter/Inter-Thin-BETA.woff2') format('woff2'),
    url('inter/Inter-Thin-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  src: url('inter/Inter-ThinItalic-BETA.woff2') format('woff2'),
    url('inter/Inter-ThinItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('inter/Inter-ExtraLight-BETA.woff2') format('woff2'),
    url('inter/Inter-ExtraLight-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: url('inter/Inter-ExtraLightItalic-BETA.woff2') format('woff2'),
    url('inter/Inter-ExtraLightItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('inter/Inter-Light-BETA.woff2') format('woff2'),
    url('inter/Inter-Light-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: url('inter/Inter-LightItalic-BETA.woff2') format('woff2'),
    url('inter/Inter-LightItalic-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('inter/Inter-Regular.woff2') format('woff2'),
    url('inter/Inter-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('inter/Inter-Italic.woff2') format('woff2'),
    url('inter/Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('inter/Inter-Medium.woff2') format('woff2'),
    url('inter/Inter-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url('inter/Inter-MediumItalic.woff2') format('woff2'),
    url('inter/Inter-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('inter/Inter-SemiBold.woff2') format('woff2'),
    url('inter/Inter-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('inter/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('inter/Inter-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('inter/Inter-Bold.woff2') format('woff2'),
    url('inter/Inter-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url('inter/Inter-BoldItalic.woff2') format('woff2'),
    url('inter/Inter-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('inter/Inter-ExtraBold.woff2') format('woff2'),
    url('inter/Inter-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url('inter/Inter-ExtraBoldItalic.woff2') format('woff2'),
    url('inter/Inter-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('inter/Inter-Black.woff2') format('woff2'),
    url('inter/Inter-Black.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url('inter/Inter-BlackItalic.woff2') format('woff2'),
    url('inter/Inter-BlackItalic.woff') format('woff');
}
